import React from "react"
import styles from "./button.module.scss"

export const Button = ({ bgColour, textColour, text, href, icon, ...rest }) => {
  return (
    <a
      href={href}
      className={styles.button}
      style={{ backgroundColor: bgColour, color: textColour }}
      {...rest}
    >
      {text}
      {icon ? <img src={icon} alt="" /> : null}
    </a>
  )
}

export const ButtonClear = ({
  borderColour,
  textColour,
  text,
  href,
  ...rest
}) => {
  return (
    <a
      href={href}
      className={styles.buttonClear}
      style={{ border: `1px solid ${borderColour}`, color: textColour }}
      {...rest}
    >
      {text}
    </a>
  )
}
