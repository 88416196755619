import React, { useState } from "react"

import styles from "./header.module.scss"
import LogoLight from "./logo_light.png"
import { ButtonClear } from "../button"
import Hamburger from "../../images/hamburger_menu.svg"

const Header = () => {
  const [navIsOpen, setNavOpen] = useState(false)

  return (
    <>
      <div
        className={`${styles.navToggle} ${
          navIsOpen ? styles.active : "inactive"
        }`}
        tabIndex={0}
        role="button"
        onClick={e => setNavOpen(!navIsOpen)}
        onKeyPress={e => setNavOpen(!navIsOpen)}
      >
        <img src={Hamburger} alt="Open Nav" />
      </div>
      <header
        className={`${styles.header} ${navIsOpen ? "visible" : styles.hidden}`}
      >
        <nav className={styles.nav}>
          <div className={styles.logo}>
            <img src={LogoLight} alt="" />
          </div>
          <div className={styles.navItems}>
            <a href="">Services</a>
            <ButtonClear href="" text="Contact" style={{ width: "128px" }} />
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
